import {
  Grid,
  Box,
  Typography,
  CircularProgress,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  Button,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Plan from '../../components/Plan';
import { selectQualify } from './qualifyByLocIdSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import checkOutSessionSlice, {
  fetchPaymentLink,
  selectCheckOutSession,
} from '../check_out_session/checkOutSessionSlice';
import { selectNBNCoAddress } from '../nbn_co_places/nbnCoAddressSlice';

interface CaptureEmailProps {
  loc_id: string;
  formatted_address: string;
  stripe_product_id: string;
}

const CaptureEmail = ({ loc_id, formatted_address, stripe_product_id }: CaptureEmailProps) => {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState('');
  const checkoutState = useAppSelector(selectCheckOutSession);

  return (
    <Grid>
      <Grid container display="flex" sx={{ justifyContent: { md: 'center', xs: 'normal' } }}>
        <Grid item sx={{ mb: 4, mt: { md: 0, xs: 4 } }}>
          <Typography color="common.white" variant="h4">
            Let&apos;s check if you are an existing customer
          </Typography>
          <Typography color="common.white" variant="subtitle1" marginBottom="30px">
            A simple step before the checkout
          </Typography>
          {checkoutState.status === 'loading' && (
            <Grid sx={{ mb: 4 }}>
              <Typography variant="h4" color="white" marginBottom="30px">
                Redirecting to the checkout page...
              </Typography>
              <br />
              <CircularProgress />
            </Grid>
          )}
          <FormControl error={email !== '' && !email.match(/^\S+@\S+\.\S+$/)}>
            <TextField
              placeholder="Please provide your email address"
              InputProps={{ style: { borderRadius: 50, marginBottom: 20 } }}
              inputProps={{ min: 0, style: { textAlign: 'center' } }}
              type="email"
              autoCorrect="off"
              autoCapitalize="none"
              style={{
                borderRadius: '50px',
                width: '300px',
              }}
              color={email !== '' && !email.match(/^\S+@\S+\.\S+$/) ? 'error' : 'primary'}
              variant="outlined"
              onChange={(val) => setEmail(val.target.value)}
              label={
                email !== '' && !email.match(/^\S+@\S+\.\S+$/)
                  ? 'Please provide a valid email address'
                  : ''
              }
              id="email"
            />
            <Button
              style={{ borderRadius: '50px', marginTop: '20px' }}
              variant="contained"
              onClick={() => dispatch(
                fetchPaymentLink({
                  email,
                  loc_id,
                  formatted_address,
                  stripe_product_id,
                }),
              )}
              disabled={!email.match(/^\S+@\S+\.\S+$/)}
            >
              Continue to checkout
            </Button>
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const QualifyByLocId = () => {
  const qualifyState = useAppSelector(selectQualify);
  const checkOutSessState = useAppSelector(selectCheckOutSession);
  const nbnState = useAppSelector(selectNBNCoAddress);
  const [productId, setProductId] = useState('');

  useEffect(() => {
    if (
      checkOutSessState.value.payment_link !== ''
      && checkOutSessState?.value?.payment_link !== undefined
    ) {
      window.location.href = checkOutSessState.value.payment_link;
    }
  }, [checkOutSessState.value]);

  if (qualifyState.status === 'loading') {
    return (
      <Grid>
        <Typography variant="h4" color="white">
          We are retrieving available plans, please stay put.
        </Typography>
        <br />
        <CircularProgress />
      </Grid>
    );
  }

  if (
    qualifyState.status === 'failed'
    || (qualifyState.status === 'idle' && qualifyState.value?.plans === undefined)
  ) {
    return (
      <Grid>
        <Typography variant="h4" color="white">
          Unfortunately
          {' '}
          <Box color="primary.main" fontWeight="fontWeightBold" component="strong">
            Neptune Internet&apos;s Early Access Program
          </Box>
          {' '}
          is unavailable at your address.
        </Typography>
      </Grid>
    );
  }

  return productId !== '' && nbnState?.formattedAddress !== undefined
    && nbnState.id !== undefined ? (
      <CaptureEmail
        stripe_product_id={productId}
        formatted_address={nbnState?.formattedAddress}
        loc_id={nbnState?.id}
      />
    ) : (
      <Grid>
        <Grid container display="flex" sx={{ justifyContent: { md: 'center', xs: 'normal' } }}>
          <Grid item sx={{ mb: 4, mt: { md: 0, xs: 4 } }}>
            <Typography color="common.white" variant="h4">
              Let&apos;s get you connected
            </Typography>
            <Typography color="common.white" variant="subtitle1">
              Choose one of the plans below and leave the rest to us. You&apos;ll be on Neptune in no time.
            </Typography>
          </Grid>
        </Grid>
        <Grid container display="flex" alignItems="stretch" justifyContent="center" spacing={2}>
          {qualifyState.value.plans.map((plan, i) => (
            <Grid flex="0 0 100%" item md={3} key={plan.id}>
              <Plan
                setProductId={setProductId}
                key={plan.id}
                discountedRate={plan.down_mbps === 50 ? 0.8 : 0.7}
                discountedPeriod={6}
                description={plan.description}
                up_mbps={plan.up_mbps}
                down_mbps={plan.down_mbps}
                product_id={plan.stripe_product_id}
                name={plan.name}
                cost={plan.cost}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    );
};
