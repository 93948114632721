import React, { useEffect } from 'react';
import {
  AppBar, Box, Button, Container, Grid, Paper, Toolbar, Typography,
} from '@mui/material';
import Logo from './images/neptune-logo-white.png';
import { QualifyByLocId } from './features/qualify_by_loc_id/QualifyByLocId';
import { NBNCoPlaces } from './features/nbn_co_places/NBNCoPlaces';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { fetchPlans } from './features/qualify_by_loc_id/qualifyByLocIdSlice';
import { selectNBNCoAddress } from './features/nbn_co_places/nbnCoAddressSlice';
import Footer from './components/Footer';

const styles = {
  // this group of buttons will be aligned to the right side
  toolbarButtons: {
    marginLeft: 'auto',
  },
};

const App = () => {
  const address = useAppSelector(selectNBNCoAddress);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (address === null) {
      return undefined;
    }
    const inFlight = dispatch(fetchPlans({ locationId: address.id, sourceType: 'nbn' }));
    return inFlight.abort;
  }, [dispatch, address]);

  return (
    <>
      <Paper>
        <AppBar
          sx={{
            py: '1.5rem',
            display: 'flex',
            position: {
              md: 'fixed',
              xs: 'static',
            },
          }}
          color="transparent"
        >
          <Toolbar>
            <Box display="flex" flexGrow={1}>
              <img alt="Neptune Internet logo" width="150px" src={Logo} />
            </Box>
            <Button
              onClick={() => {
                window.location.href = 'https://triton.neptune.net.au';
              }}
              variant="outlined"
              style={{
                borderRadius: '20px',
              }}
            >
              Sign In
            </Button>
          </Toolbar>
        </AppBar>
        <Container sx={{ height: '80vh' }} maxWidth="lg" className="App">
          <Grid
            sx={{
              ml: {
                width: '100%',
                xs: '0',
              },
            }}
            container
            spacing={2}
            display="flex"
            height="100%"
            justifyContent="center"
            alignItems="center"
          >
            {address === null && (
              <Grid>
                <Typography gutterBottom color="common.white" variant="h1">
                  Get fast and reliable Internet at an exclusive price.
                </Typography>
                <Typography
                  gutterBottom
                  sx={{
                    mb: {
                      md: '3rem',
                      xs: '2rem',
                    },
                  }}
                  color="common.white"
                  variant="subtitle1"
                >
                  You’re invited to
                  {' '}
                  <Box color="primary.main" fontWeight="fontWeightBold" component="strong">
                    Neptune Internet&apos;s Early Access Program
                  </Box>
                  . Sign up now for fast and reliable nbn® Internet at a little more than cost
                  price.
                </Typography>
                <NBNCoPlaces />
              </Grid>
            )}
            {address !== null && <QualifyByLocId />}
          </Grid>
        </Container>
      </Paper>
      <Footer />
    </>
  );
};

export default App;
