import React from 'react';
import Card, { CardProps } from '@mui/material/Card';
import { styled, lighten } from '@mui/material/styles';

export type StyledCardProps = CardProps

const StyledCard = styled(Card)<StyledCardProps>(({ theme, elevation = 0 }) => ({
  color: theme.palette.common.white,
  backgroundColor: lighten(theme.palette.background.paper, elevation * 0.15),
  borderRight: `1px solid ${lighten(theme.palette.background.paper, elevation * 0.25)}`,
  borderBottom: `1px solid ${lighten(theme.palette.background.paper, elevation * 0.3)}`,
}));

export default StyledCard;
