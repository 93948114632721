import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { ProteusPayload, ProteusResponse } from '../../models/proteus';
import { fetchPlansByAddress } from './qualifyAPI';

export interface QualifyState {
  value: ProteusResponse;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: QualifyState = {
  value: {} as ProteusResponse,
  status: 'idle',
};

export const fetchPlans = createAsyncThunk(
  'qualify/fetchPlans',
  async (payload: ProteusPayload) => {
    const response = await fetchPlansByAddress(payload);
    // sort plans by downsspeed ascending
    const plans = response.data.plans.sort((a: any, b: any) => a.speedDown - b.speedDown);

    return {
      ...response.data,
      plans,
      address: payload,
    };
  },
);

export const qualifySlice = createSlice({
  name: 'qualify',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlans.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPlans.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(fetchPlans.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const selectQualify = (state: RootState) => state.qualify.value;
export default qualifySlice.reducer;
