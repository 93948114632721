import React from 'react';
import {
  Box, Button, Container, Grid, Link, Paper, Toolbar, Typography,
} from '@mui/material';
import Logo from '../images/neptune-logo-white.png';

const Footer = () => (
  <Paper
    sx={{
      marginTop: 'calc(10% + 60px)',
      width: '100%',
      position: 'fixed',
      bottom: 0,
    }}
    component="footer"
    square
  >
    <Container maxWidth="lg">
      <Box
        sx={{
          flexGrow: 1,
          justifyContent: 'center',
          display: 'flex',
          my: 1,
        }}
      >
        <Typography
          gutterBottom
          color="common.white"
          variant="subtitle2"
        >
          © Neptune Internet 2024. All rights reserved.
        </Typography>
      </Box>

      <Box
        sx={{
          flexGrow: 1,
          justifyContent: 'center',
          display: 'flex',
          mb: {
            md: '1rem',
            xs: '0rem',
          },
        }}
      >
        <Button style={{ borderRadius: '50px' }} href="https://status.neptune.net.au/">Status Page</Button>

      </Box>
    </Container>
  </Paper>
);
export default Footer;
