import axios from 'axios';
import { ProteusPayload } from '../../models/proteus';

export function fetchPlansByAddress(payload: ProteusPayload) {
  return axios.post(
    '/api/v1/qualify',
    {
      ...payload,
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );
}
