import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { CheckOutSessionRequest, CheckOutSessionResponse } from '../../models/proteus';

export interface CheckOutSessionState {
  value: CheckOutSessionResponse;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: CheckOutSessionState = {
  value: {} as CheckOutSessionResponse,
  status: 'idle',
};

export const fetchPaymentLink = createAsyncThunk(
  'checkOutSession/fetchCheckoutSession',
  async (payload: CheckOutSessionRequest) => {
    const response = await fetch('/api/v1/checkout', {
      method: 'POST',
      headers: { Accept: 'application/json' },
      body: JSON.stringify(payload),
    })
      .then((resp) => resp.json())
      .catch((error) => {
        throw error;
      });

    return {
      ...response,
    };
  },
);

export const checkOutSessionSlice = createSlice({
  name: 'checkOutSession',
  initialState,
  reducers: {
    reset: (state) => {
      state = { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPaymentLink.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPaymentLink.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(fetchPaymentLink.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const selectCheckOutSession = (state: RootState) => state.checkOutSession;
export default checkOutSessionSlice;
