import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import qualifyReducer from '../features/qualify/qualifySlice';
import nbnCoAddress from '../features/nbn_co_places/nbnCoAddressSlice';
import nbnCoPlaces from '../features/nbn_co_places/nbnCoPlacesSlice';
import qualifyByLocId from '../features/qualify_by_loc_id/qualifyByLocIdSlice';
import checkOutSession from '../features/check_out_session/checkOutSessionSlice';

export const store = configureStore({
  reducer: {
    qualify: qualifyReducer,
    nbnCoPlaces: nbnCoPlaces.reducer,
    nbnCoAddress: nbnCoAddress.reducer,
    qualifyByLocId: qualifyByLocId.reducer,
    checkOutSession: checkOutSession.reducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
