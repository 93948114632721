import React, { useEffect, useMemo, useState } from 'react';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import {
  Box, Grid, Typography, TextField, Autocomplete, Button,
} from '@mui/material';
import { LocationOn } from '@mui/icons-material';
import nbnCoAddressSlice from './nbnCoAddressSlice';
import nbnCoPlacesSlice, { fetchPlaces, selectNBNCoPlaces } from './nbnCoPlacesSlice';
import { NBNCoAddress } from '../../models/proteus';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

function useDebounce<T>(initialValue: T, time: number): [T, React.Dispatch<T>] {
  const [value, setValue] = useState<T>(initialValue);
  const [debouncedValue, setDebouncedValue] = useState<T>(initialValue);

  useEffect(() => {
    const debounce = setTimeout(() => {
      setDebouncedValue(value);
    }, time);
    return () => {
      clearTimeout(debounce);
    };
  }, [value, time]);

  return [debouncedValue, setValue];
}

export const NBNCoPlaces = () => {
  const dispatch = useAppDispatch();
  const placesState = useAppSelector(selectNBNCoPlaces);
  const setAddress = (address: NBNCoAddress | null) => dispatch(nbnCoAddressSlice.actions.setAddress(address));

  const [inputValue, setInputValue] = useDebounce('', 500);
  const [selectedAddress, setSelectedAddress] = useState<NBNCoAddress | null>(null);

  useEffect(() => {
    if (inputValue === '') {
      dispatch(nbnCoPlacesSlice.actions.reset());
      return;
    }
    const inFlight = dispatch(fetchPlaces(inputValue));
  }, [inputValue, dispatch]);

  const noOptionsText = useMemo(() => {
    if (placesState.status === 'loading') {
      return 'Searching...';
    }

    if (inputValue === '') {
      return 'Please type your address';
    }

    return 'No locations found';
  }, [inputValue, placesState.status]);

  return (
    <form id="place" onSubmit={() => selectedAddress && setAddress(selectedAddress)}>
      <Autocomplete
        filterOptions={(x) => x} // Override default filterOptions to avoid filtering on the client
        getOptionLabel={(option) => option.formattedAddress}
        isOptionEqualToValue={(option, value) => option.formattedAddress === value.formattedAddress}
        options={placesState.value}
        includeInputInList
        value={selectedAddress}
        noOptionsText={noOptionsText}
        onChange={(_event, newValue) => setSelectedAddress(newValue)}
        onInputChange={(_event, newInputValue) => setInputValue(newInputValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Please type your address"
            sx={{
              '& input:focus::placeholder': {
                color: 'transparent',
              },
              '& .MuiInputBase-root': {
                '& legend ': {
                  display: 'none',
                },
                '& > fieldset': {
                  borderWidth: '2px',
                  borderRadius: '50px',
                },
                paddingTop: '4.5px',
              },
              '& .MuiInputBase-input': {
                textAlign: 'center',
              },
            }}
            InputProps={{
              ...params.InputProps,
              autoComplete: 'off',
              endAdornment: (
                <>
                  {params.InputProps.endAdornment}
                  {selectedAddress ? (
                    <Button
                      type="submit"
                      variant="contained"
                      size="small"
                      form="place"
                      sx={{ borderRadius: '50px' }}
                    >
                      See Plans
                    </Button>
                  ) : null}
                </>
              ),
            }}
          />
        )}
        renderOption={(props, option) => {
          const matches = match(option.formattedAddress, inputValue, {
            insideWords: true,
          });
          const parts = parse(option.formattedAddress, matches);

          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ display: 'flex', width: 44 }}>
                  <LocationOn sx={{ color: 'text.primary' }} />
                </Grid>
                <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                  {parts.map((part, index) => (
                    <Box
                      key={part.text}
                      component="span"
                      sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                    >
                      {part.text}
                    </Box>
                  ))}
                  <Typography variant="body2" color="text.secondary">
                    {option.formattedAddress}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    </form>
  );
};
