import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HOST } from '../../app/consts';
import { RootState } from '../../app/store';
import { QualifyByLocIdPayload, QualifyByLocIdResponse } from '../../models/proteus';

export interface QualifyByLocIdState {
  value: QualifyByLocIdResponse;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: QualifyByLocIdState = {
  value: {} as QualifyByLocIdResponse,
  status: 'idle',
};

export const fetchPlans = createAsyncThunk(
  'qualifyByLocId/fetchPlans',
  async (payload: QualifyByLocIdPayload) => {
    const response = await fetch('/api/v1/qualify-by-loc-id', {
      method: 'POST',
      headers: { Accept: 'application/json' },
      body: JSON.stringify(payload),
    })
      .then((resp) => resp.json())
      .catch((error) => {
        throw error;
      });

    // sort plans by downspeed ascending
    const plans = response.plans.sort((a: any, b: any) => a.speedDown - b.speedDown);

    return {
      ...response,
      plans,
      address: payload,
    };
  },
);

export const qualifyByLocIdSlice = createSlice({
  name: 'qualifyByLocId',
  initialState,
  reducers: {
    reset: (state) => {
      state = { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlans.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPlans.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(fetchPlans.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const selectQualify = (state: RootState) => state.qualifyByLocId;
export default qualifyByLocIdSlice;
