import * as React from 'react';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, CardHeader } from '@mui/material';
import StyledCard from './StyledCard';

export interface PlanProps {
  name: string;
  cost: number;
  discountedRate?: number;
  discountedPeriod?: number;
  up_mbps: number;
  down_mbps: number;
  product_id: string;
  description: string;
  setProductId: any;
}

const Plan = ({
  setProductId,
  name,
  up_mbps,
  down_mbps,
  cost,
  description,
  product_id,
  discountedRate = 1,
  discountedPeriod = 0,
}: PlanProps) => {
  const discountedPrice = cost * (discountedRate || 1);

  const subhead = discountedPrice !== cost && discountedPeriod !== undefined
    ? `$${discountedPrice.toFixed(2)} per month for ${discountedPeriod} months`
    : `$${cost} per month`;

  return (
    <StyledCard sx={{ height: '100%' }} elevation={1}>
      <CardActionArea
        sx={{
          py: 2, display: 'flex', flexDirection: 'column', height: '100%',
        }}
        onClick={() => setProductId(product_id)}
      >
        <CardHeader
          subheader={subhead}
          subheaderTypographyProps={{
            variant: 'body1',
            color: 'common.white',
          }}
          title={`${name} ${down_mbps}`}
        />
        <CardContent>
          <Typography variant="h6">
            {down_mbps}
            mbps /
            {up_mbps}
            mbps
          </Typography>
          <br />
          <Typography variant="body2">{description}</Typography>
        </CardContent>
        <CardMedia
          component="img"
          alt={`Neptune Internet plan ${name}`}
          image={`plan_${name.toLowerCase()}.png`}
          height="175px"
          sx={{
            mt: 'auto', px: 0, py: 4, objectFit: 'contain', alignSelf: 'end',
          }}
        />
      </CardActionArea>
    </StyledCard>
  );
};

export default Plan;
