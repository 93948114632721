import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material';
import { store } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';

const container = document.getElementById('root')!;
const root = createRoot(container);
const defaultTheme = createTheme();

const theme = createTheme({
  palette: {
    action: {
      disabled: '#F72585',
      active: '#F72585',
    },
    primary: {
      main: '#00FFFF',
      contrastText: '#000033',
    },
    secondary: {
      main: '#000099',
      contrastText: '#00FFFF',
    },
    background: {
      default: '#000000',
      paper: '#000000',
    },
    mode: 'dark',
  },
  typography: {
    fontFamily: 'Montserrat, Helvetica, sans-serif',
    h1: {
      fontFamily: 'Belfast Grotesk, Helvetica, sans-serif',
      fontSize: '4.5rem',
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '2.5rem',
      },
    },
    h2: {
      fontFamily: 'Belfast Grotesk, Helvetica, sans-serif',
    },
    h3: {
      fontFamily: 'Belfast Grotesk, Helvetica, sans-serif',
    },
    h4: {
      fontFamily: 'Belfast Grotesk, Helvetica, sans-serif',
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '1.5rem',
      },
    },
    h5: {
      fontFamily: 'Belfast Grotesk, Helvetica, sans-serif',
    },
    h6: {
      fontFamily: 'Belfast Grotesk, Helvetica, sans-serif',
    },
    subtitle1: {
      fontFamily: 'Montserrat, Helvetica, sans-serif',
      fontSize: '1.5rem',
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '1rem',
      },
    },
    button: {
      fontWeight: '700',
      textTransform: 'none',
    },
  },
});

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
